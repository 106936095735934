// src/components/Phone.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  VStack,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Container,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../Firebase';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength === 0) return '';
  if (phoneNumberLength < 4) return `(${phoneNumber}`;
  if (phoneNumberLength < 7)
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6,
    10
  )}`;
};

const Phone = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState('phone');
  const [resendTimer, setResendTimer] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const formatted = formatPhoneNumber(input);
    setPhoneNumber(formatted);
  };

  const sendVerificationCode = async () => {
    const digits = phoneNumber.replace(/[^\d]/g, '');

    if (digits.length !== 10) {
      setError('Please enter a valid 10-digit phone number.');
      return;
    }

    const normalizedPhoneNumber = `+1${digits}`;

    try {
      const response = await fetch(`${API_BASE_URL}/send-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: normalizedPhoneNumber }),
      });
      const data = await response.json();
      if (data.success) {
        setStep('code');
        setError('');
        setResendTimer(60);
        toast({
          title: 'Verification code sent.',
          description: 'Please check your phone for the verification code.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        setError(data.message || 'Failed to send verification code.');
        toast({
          title: 'Error sending code.',
          description: data.message || 'Failed to send verification code.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      setError('Error sending verification code. Please try again later.');
      toast({
        title: 'Error sending code.',
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  };

  const handleSendCode = () => {
    sendVerificationCode();
  };

  const handleResendCode = () => {
    if (resendTimer > 0) {
      toast({
        title: 'Please wait before resending.',
        description: `You can resend the code in ${resendTimer} seconds.`,
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    sendVerificationCode();
  };

  const handleVerifyCode = async () => {
    const digits = phoneNumber.replace(/[^\d]/g, '');
    const normalizedPhoneNumber = `+1${digits}`;

    if (!verificationCode.trim()) {
      setError('Please enter the verification code.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/verify-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          phoneNumber: normalizedPhoneNumber,
          verificationCode: verificationCode.trim(),
        }),
      });
      const data = await response.json();
      if (data.success) {
        await signInWithCustomToken(auth, data.token);
        setError('');
        toast({
          title: 'Verification successful.',
          description: 'You have been signed in.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/dashboard');
      } else {
        setError(data.message || 'Failed to verify code.');
        toast({
          title: 'Verification failed.',
          description: data.message || 'Failed to verify code.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      setError('Error verifying code. Please try again later.');
      toast({
        title: 'Error verifying code.',
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  };

  return (
    <Container maxW="md" mt={8}>
      <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
        <VStack spacing={4} align="stretch">
          <Heading as="h2" size="lg" textAlign="center">
            Continue with Phone Number
          </Heading>

          {error && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {step === 'phone' ? (
            <>
              <Input
                type="tel"
                placeholder="(123) 456-7890"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                maxLength={14}
              />
              <Button colorScheme="blue" onClick={handleSendCode}>
                Send Verification Code
              </Button>
            </>
          ) : (
            <>
              <Input
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                autoComplete="one-time-code"
                inputMode="numeric"
                pattern="[0-9]*"
              />
              <Button colorScheme="blue" onClick={handleVerifyCode}>
                Verify Code
              </Button>

              <Flex justify="center" align="center" mt={4}>
                <Button
                  variant="link"
                  onClick={handleResendCode}
                  p={0}
                  _hover={{ textDecoration: 'none' }}
                >
                  <Text fontSize="sm" textAlign="center" display="block">
                    Didn't receive the code?{' '}
                    <Text as="span" color="blue.500" textDecoration="underline">
                      Resend
                    </Text>
                  </Text>
                </Button>
              </Flex>
            </>
          )}
        </VStack>
      </Box>
    </Container>
  );
};

export default Phone;
