// Login.js
import React, { useState } from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  Container,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { auth } from "../Firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [authError, setAuthError] = useState("");

  const handlePhoneLogin = () => {
    navigate("/login/phone");
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      setAuthError("Failed to sign in with Google. Please try again.");
    }
  };

  const handleEmailLogin = async () => {
    let valid = true;
    if (!email) {
      setIsEmailError(true);
      valid = false;
    } else {
      setIsEmailError(false);
    }

    if (!password) {
      setIsPasswordError(true);
      valid = false;
    } else {
      setIsPasswordError(false);
    }

    if (!valid) return;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during email login:", error);
      setAuthError("Invalid email or password. Please try again.");
    }
  };

  return (
    <Container maxW="md" mt={8}>
      <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
        <VStack spacing={6} align="stretch">
          <Heading as="h2" size="lg" textAlign="center">
            Login
          </Heading>

          <FormControl isInvalid={isEmailError} isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {isEmailError && (
              <FormErrorMessage>Email is required.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={isPasswordError} isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {isPasswordError && (
              <FormErrorMessage>Password is required.</FormErrorMessage>
            )}
          </FormControl>

          {authError && (
            <Box color="red.500" textAlign="center">
              {authError}
            </Box>
          )}

          <Button colorScheme="teal" onClick={handleEmailLogin}>
            Login
          </Button>

          <Divider />

          <VStack spacing={4} align="stretch">
            <Button colorScheme="blue" onClick={handlePhoneLogin}>
              Continue with Phone Number
            </Button>

            <Button colorScheme="red" onClick={handleGoogleLogin}>
              Continue with Google
            </Button>
          </VStack>

          <Box textAlign="center" mt={4}>
            Don't have an account?{" "}
            <Link as={RouterLink} to="/signup" color="teal.500">
              Sign Up
            </Link>
          </Box>
        </VStack>
      </Box>
    </Container>
  );
};

export default Login;
