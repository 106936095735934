// utils/DirectRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const DirectRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  return !user ? children : <Navigate to="/dashboard" />;
};

export default DirectRoute;
