// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Phone from './components/Phone';
import Dashboard from './components/Dashboard';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './utils/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import DirectRoute from './utils/DirectRoute';
import Login from './components/Login';

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/signup" replace />} />
            <Route path="/signup" element={<DirectRoute><Signup /></DirectRoute>} />
            <Route path="/signup/phone" element={<DirectRoute><Phone /></DirectRoute>} />
            <Route path="/login" element={<DirectRoute><Login /></DirectRoute>} />
            <Route path="/login/phone" element={<DirectRoute><Phone /></DirectRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}/>
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
