// src/components/Dashboard.js
import React, { useContext } from 'react';
import { AuthContext } from '../utils/AuthContext'; // Import AuthContext
import { Box, Heading, Text, Button, Container, Spinner } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { auth } from '../Firebase'; 
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { user, loading } = useContext(AuthContext); // Access user and loading state from AuthContext
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/signup'); // Redirect to signup page after logout
    } catch (error) {
      console.error('Error signing out:', error);
      // Optionally, display an error message to the user using a toast or alert
    }
  };

  if (loading) {
    return (
      <Container maxW="md" mt={8}>
        <Box bg="white" p={6} borderRadius="lg" boxShadow="md" textAlign="center">
          <Spinner size="xl" />
          <Text mt={4}>Loading...</Text>
        </Box>
      </Container>
    );
  }

  if (!user) {
    // If user is not authenticated, redirect to signup page
    navigate('/signup');
    return null; // Prevent rendering the rest of the component
  }

  return (
    <Container maxW="md" mt={8}>
      <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
        <Heading as="h2" size="lg" mb={4} textAlign="center">
          Dashboard
        </Heading>
        {user.email ? (
          <Text>
            Welcome! Your email address is: <strong>{user.email}</strong>
          </Text>
        ) : user.phoneNumber ? (
          <Text>
            Welcome! Your phone number is: <strong>{user.phoneNumber}</strong>
          </Text>
        ) : (
          <Text>Welcome! No email or phone number available.</Text>
        )}
        <Button mt={4} colorScheme="blue" onClick={handleLogout}>
          Log Out
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard;
